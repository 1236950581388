import { GraphQLClient } from 'graphql-request';

export function gqlValidation(d) {
	if (d.error && d.validation) {
		return d.validation;
	}

	return null;
}

export const gqlData = (data) => {
	return data || false;
};

export const setHeaders = (requestName, headers = {}) => {
	return { 'Request-Name': requestName, ...headers };
};

export const setContext = (requestName, context = {}) => {
	return {
		headers: setHeaders(requestName.replace(/-/g, '_')),
		uri: `${process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_API_URL_DEV : process.env.NEXT_PUBLIC_API_URL}graphql?request=${requestName.replace('>', '--') || 'unknown'}`,
		...context
	};
};

export const parseCookie = (str) => {
	return str ? str
		.split(';')
		.map(v => v.split('='))
		.reduce((acc, v) => {
			acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
			return acc;
		}, {}) : {};
};

// ANY CHANGE HERE, change also in: useGQL, helpers/gql SSRLoader (if necessary)
export const SSRLoader = async (name, params, request, config, options) => {
	// Get dat only on server render, not after browser page transition
	const isClient = (!request || (request.url && request.url.startsWith('/_next/data')));

	if (isClient === false) {
		const cookies = parseCookie(request.headers.cookie);
		const { urisegment } = params;

		// Parse urisegment, to get real_estate_id (last segment)
		const uriParts = urisegment.split('-');

		const client = new GraphQLClient(`${process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_API_URL_DEV : process.env.NEXT_PUBLIC_API_URL}graphql`, {
			headers: {
				'Request-Name': config.request.name,
				Environment: process.env.NODE_ENV,
				Apikey: process.env.NEXT_PUBLIC_API_KEY,
				Authorization: cookies.access_token ? `Bearer ${cookies.access_token}` : null
			}
		});

		const variables = {};

		switch(name) {
		case 'auction': variables.real_estate_id = uriParts[uriParts.length - 1]; break;
		case 'content': variables.filename = urisegment || null; break;
		default: break;
		}

		const data = await client.request(config.gql.schema, variables);

		// 404 page
		if (!data || !data.request || !data.request[options.exist_key_check]) {
			return { notFound: true };
		}

		// Detect device
		const userAgent = request.headers['user-agent'];

		return {
			props: {
				SSRResult: options.result_key ? data.request[options.result_key] : data.request,
				SSRIsMobile: userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i) !== null
			}
		};
	}

	return { props: {} };
};
