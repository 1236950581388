import { useState, useEffect, useCallback } from 'react';

import useLanguage from './useLanguage';

function useForm(action = 'insert', formState = {}, formSchema = {}, callback) {
	// Languages
	const { t } = useLanguage();

	const [isDev] = useState(process.env.NODE_ENV === 'development');

	const [state, setState] = useState(null);
	const [disabled, setDisabled] = useState(true); // true = before, current = if at least one data is prefilled - then disabled is false on load
	const [changed, setChanged] = useState(false);

	// On mount
	useEffect(() => {
		if (Object.keys(formSchema).length > 0) {
			setChanged(true);
			setDisabled(false);

			// Create initial state with all field, which exist in form - prefill only thouse, for which initial state isset
			const s = {};

			Object.keys(formSchema).map((name) => {
				s[name] = getFormFieldValue(name, formState, formSchema);
				return true;
			});

			if (isDev) {
				console.log('FORM INIT STATE', s);
			}

			setState(s);
		}
	}, []);

	// Used to disable submit button if there's an error in state
	// or the required field in state has no value.
	// Wrapped in useCallback to cached the function to avoid intensive memory leaked
	// in every re-render in component
	const validateState = useCallback(() => {
		const hasErrorInState = Object.keys(formSchema).some((key) => {
			const isInputFieldRequired = formSchema[key].required;
			let stateValue = state[key].value || ''; // state value
			const stateError = state[key].error || ''; // state error

			// Handle required in case of collector (array of values)
			if (formSchema[key].collector === true) {
				stateValue = (state[key].value.length > 0);
			}

			return (isInputFieldRequired && !stateValue) || stateError;
		});

		return hasErrorInState; // if return TRUE, then form is not valid - if return FALSE, then form is valid
	}, [state, formSchema]);

	// Validate complete for, to get all errors (in client - when client is valid, request go to server and second layer of validation is triggered)
	const validateForm = (applyErrorsToState = false, aState = null, aSchema = null, aSchemaStep = null) => {
		const errors = {};
		let isValid = true;

		const data = aState ? { ...aState } : state; // injected ("clone created") or global
		const schema = aSchema ? { ...aSchema } : { ...formSchema }; // injected ("clone created") or global

		// Filter out only field relative to provided step
		if (aSchemaStep) {
			Object.keys(schema).map((key) => {
				if (schema[key].step && schema[key].step !== aSchemaStep) {
					delete schema[key];
				}

				return true;
			});
		}

		Object.keys(schema).map((key) => {
			const validation = validateField(key, data[key] ? data[key].value : '', data, schema);
			errors[key] = validation.error || null;

			if (isValid === true && errors[key] !== null) {
				isValid = false;
			}

			return true;
		});

		if (applyErrorsToState === true) {
			setError(errors);
		}

		return isValid ? true : errors;
	};

	const executeValidationRules = (rules, v) => {
		let message = '';

		rules.map((rule) => {
			if (rule.name) {
				switch (rule.name) {
				case 'length': {
					if (rule.value && v.toString().length !== rule.value) message = `${t(rule.tId || 'n/a error message')} ${rule.value}`;
					break;
				}

				case 'maxLength': {
					if (rule.value && v.toString().length <= rule.value) message = `${t(rule.tId || 'n/a error message')} ${rule.value}`;
					break;
				}

				case 'minLength': {
					if (rule.value && v.toString().length >= rule.value) message = `${t(rule.tId || 'n/a error message')} ${rule.value}`;
					break;
				}

				case 'numeric': {
					if (isNaN(v)) message = t(rule.tId || 'n/a error message');
					break;
				}

				case 'regEx': {
					if (v && !rule.regEx.test(v)) message = t(rule.tId || 'n/a error message');
					break;
				}

				default: break;
				}
			}

			return true;
		});

		return message;
	};

	const validateField = (name, value, aState = null, aSchema = null) => {
		let v = value;

		const data = aState ? { ...aState } : state; // injected ("clone created") or global
		const schema = aSchema ? { ...aSchema } : { ...formSchema }; // injected ("clone created") or global

		if (schema[name]) {
			const { required, requiredif, validator, rules } = schema[name];

			// Handle required in case of collector (array of values)
			if (schema[name].collector === true) {
				v = (data[name].value.length > 0);
			}

			if (schema[name].falseIfEmpty === true && (!data[name].value || data[name].value.length === 0)) {
				v = false;
			}

			let errorMessage = '';

			// ex. required: true/false
			if (required) {
				if (!v) {
					errorMessage = t('validationRequired');
				}
			}

			// Condition required: if some other field has value (ex. checked/true/1/...): ex. requiredif: 'floor_toggle',
			if (requiredif && data[requiredif]) {
				if (!v && data[requiredif].value) {
					errorMessage = t('validationRequired');
				}
			}

			// Simple, just regex
			if (v && validator !== null && typeof validator === 'object' && validator.regEx) {
				if (!validator.regEx.test(v)) {
					const { error, tId } = validator;
					errorMessage = error || t(tId);
				}
			}

			// List of rules
			if (v && rules !== null && typeof rules === 'object' && rules[0]) {
				errorMessage = executeValidationRules(rules, v);
			}

			return {
				value: v,
				error: errorMessage || null
			};
		}

		return {
			value: v,
			error: null
		};
	};

	// Used to handle every changes in every input
	const handleOnChange = (e, options = {}) => {
		const { target } = e;
		const { name, value, type, checked } = target;

		const isCollector = (options.collector === true); // ex checkbox group for same name (bank: [nkbm, nlb])
		const useSetValue = (options.useSetValue === true);
		const falseIfUnchecked = (options.falseIfUnchecked === true);

		let newValue = (type === 'checkbox' && !isCollector && !useSetValue) ? checked : value;

		// If "unchecked", set null - if checked (above) take value in value="" attribute
		if (useSetValue === true && checked === false) {
			newValue = null;
		}

		// If "unchecked" and "falseIfUnchecked = true", then set to false instead (default) null
		if (falseIfUnchecked === true && checked === false) {
			newValue = false;
		}

		// Handle string values "true/false" as boolena (case: radio buttons)
		if (newValue && typeof newValue === 'string') {
			if (newValue.toLowerCase() === 'true') newValue = true;
			else if (newValue.toLowerCase() === 'false') newValue = false;
		}

		// Ex. array of checkboxes with same name
		if (isCollector) {
			const v = newValue;
			newValue = (Array.isArray(state[name].value)) ? state[name].value : [];

			if (checked) {
				newValue.push(v);
			} else {
				newValue.splice(newValue.indexOf(v), 1);
			}
		}

		// Without validtion on the fly
		setState(prevState => (
			{ ...prevState, [name]: { value: newValue, error: null } }
		));

		/* VALIDATE ON THE FLY
		const validation = validateField(name, newValue);

		setState(prevState => (
			{ ...prevState, [name]: { value: newValue, error: validation.error } }
		));
		*/

		if (changed === false) {
			setChanged(true);
		}
	};

	const findFirstErrorFieldName = (errors) => { // errors:{key:value} => fname => error message/null
		const fieldName = Object.keys(errors).filter(key => errors[key] !== null);
		return fieldName[0] || null;
	};

	const findFirstErrorText = (errors) => { // errors:{key:value} => fname => error message/null
		const values = Object.values(errors).filter(value => value !== null);
		return values[0] || null;
	};

	const getFormFieldValue = (aName, aData, aSchema) => {
		const defaultValue = (aSchema[aName].collector === true) ? [] : '';

		// ex. { value: 1, error: null } or "1" or "null/not set"
		let fieldValue = aData[aName] || defaultValue;

		if (typeof aData[aName] === 'object' && aData[aName] !== null && aData[aName].value) {
			fieldValue = aData[aName].value;
		}

		// Ex. when checbox and server expect boolen (checked = true, unchecked = false)
		if (aSchema[aName].falseIfEmpty === true && (!fieldValue || fieldValue.length === 0)) {
			fieldValue = false;
		}

		// Ex. when db expect decimal, empty string '' will break sql. Null will be fine.
		if (aSchema[aName].nullifempty === true && (!fieldValue || fieldValue.length === 0)) {
			fieldValue = null;
		}

		if (fieldValue && typeof fieldValue === 'object' && Array.isArray(fieldValue)) {
			fieldValue = [...fieldValue]; // Create totaly unlinked copy of array with its parent!!!! Default by reference!!
		}

		return { value: fieldValue, error: (typeof aData[aName] === 'object' && aData[aName] !== null && aData[aName].error) ? aData[aName].error : null };
	};

	// ex. { transaction_id: 1 } ==> { transaction:id { value: 1, error: null }}
	const prepareFormReadyData = (d, aSchema = null) => {
		const schema = aSchema || formSchema; // injected ("clone created") or global
		const dta = {};

		Object.keys(d).map((name) => {
			dta[name] = getFormFieldValue(name, d, schema);
			return true;
		});

		if (isDev) {
			console.log('FORM READY DATA', dta);
		}

		return dta;
	};

	const prepareAPIReadyData = (additionalStateData, aSchema = null) => {
		const schema = aSchema || formSchema; // injected ("clone created") or global
		const resultState = {};

		// Clear state, to return only field_name:value pairs
		Object.keys(state).map((fName) => {
			let fieldValue = typeof state[fName].value !== 'undefined' ? state[fName].value : null;

			// Ex. when checbox and server expect boolen (checked = true, unchecked = false)
			if (schema[fName]) {
				if (schema[fName].falseIfEmpty === true && (!fieldValue || fieldValue.length === 0)) {
					fieldValue = false;
				}

				// Ex. when db expect decimal, empty string '' will break sql. Null will be fine.
				if (schema[fName].nullifempty === true && (!fieldValue || fieldValue.length === 0)) {
					fieldValue = null;
				}
			}

			resultState[fName] = fieldValue;
			return true;
		});

		if (additionalStateData) {
			Object.keys(additionalStateData).map((fName) => {
				resultState[fName] = additionalStateData[fName];
				return true;
			});
		}

		return resultState;
	};

	const handleOnSubmit = useCallback((e, additionalStateData) => {
		if (e && typeof e.preventDefault === 'function') {
			e.preventDefault();
		}

		let resultState = {};
		const errors = validateForm();

		if (typeof errors === 'object') {
			setError(errors);
			resultState = {
				firstErrorFieldName: findFirstErrorFieldName(errors),
				state,
				errors
			};
		} else {
			resultState = prepareAPIReadyData(additionalStateData);
		}

		callback(resultState);
	}, [state, callback, validateState]);

	const setError = useCallback((errors, returnFirst = false) => {
		Object.keys(errors).map((fName) => {
			setState(prevState => (
				{ ...prevState, [fName]: { value: prevState[fName].value, error: errors[fName] } }
			));
			return true;
		});

		if (returnFirst === true) {
			return findFirstErrorFieldName(errors);
		}

		return true;
	}, [state]);

	const updateChanged = useCallback((status) => {
		if (changed !== status) {
			setChanged(status);

			// Enable, if form is valid and remote changed is triggered
			if (status === true) {
				const requestValidation = validateState();
				if (requestValidation !== disabled) {
					setDisabled(requestValidation);
				}
			}
		}
	});

	const handleFocus = useCallback((e) => {
		const { target, type } = e;
		const { name } = target;

		setState(prevState => (
			{ ...prevState, [name]: { ...prevState[name], focus: type === 'focus' } }
		));
	});

	const setNewState = (s) => {
		setState(s);
	};

	const updateState = useCallback((data) => {
		Object.keys(data).map((fName) => {
			let modifiedData = data[fName] || ''; // value must be empty string, not null! (in case, if not exist)

			if (formSchema[fName] && formSchema[fName].falseIfEmpty === true && (!data[fName] || data[fName].length === 0)) {
				modifiedData = false;
			}

			// If array, check if children nodes does not have key "__typename"
			if (Array.isArray(data[fName])) {
				modifiedData = [];

				data[fName].map((row) => {
					if (row['__typename']) {
						delete row['__typename'];
					}

					modifiedData.push(row);
					return true;
				});
			}

			setState(prevState => (
				{ ...prevState, [fName]: { value: modifiedData, error: null } } // modifiedData || ''
			));

			return true;
		});

		return true;
	}, [state]);

	// For every changed in our state this will be fired
	// To be able to disable the button
	useEffect(() => {
		if (changed === true) {
			const requestValidation = validateState();
			// console.log('STATE HAS ERRORS', requestValidation, 'DISABLED', disabled);
			if (requestValidation !== disabled) { // ex. disabled = true & validationResult = false \ DO NOTHING, ok!
				setDisabled(requestValidation);
			}
		}
	}, [state, disabled, validateState]);

	return { state, disabled, handleFocus, handleOnChange, handleOnSubmit, prepareFormReadyData, prepareAPIReadyData, validateForm, findFirstErrorFieldName, findFirstErrorText, setError, updateState, setNewState, updateChanged };
}

export default useForm;
