import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useForm from '../_hooks/useForm';
import useGQL from '../_hooks/useGQL';
import { scrollToFirstError } from '../../helpers/form';
import { gqlValidation } from '../../helpers/gql';

// import Loader from '../loader/loader';
import AsyncComponent from '../_hoc/asyncComponent';

/*
HOW TO:
- create src/forms/<name>.js (settings config: validation, gql schema, ...)
- FormLoader>getFormSettings add case by name
- FormLoader>loadFormQuery add case by name (in case query is needed, to prefill form - ex. on edit)
*/

const FormLoader = React.memo(({ name, id, initialState, placeholders, onSuccess, onError, onPrefillSuccess, onPrefillError, onBeforeSubmitToServer, formParameters }) => {
	const initState = initialState; // reassign, so it can be appended, if needed

	const [isDev] = useState(process.env.NODE_ENV === 'development');

	// General form notification (ex. on success action)
	const [notification, setNotification] = useState(null);

	const fieldsRefs = useRef({});
	const formSettings = useRef(null);
	const formQuery = useRef(null);
	const Component = useRef(null);

	// Form component
	if (Component.current === null) {
		Component.current = AsyncComponent(() => {
			switch (name) {
			case 'auth>register': 							return import('./auth/register');
			case 'auth>login': 									return import('./auth/login');
			case 'auth>forgot-password-init': 	return import('./auth/forgot-password-init');
			case 'auth>forgot-password-reset': 	return import('./auth/forgot-password-reset');
			case 'contact>item': 								return import('./contact/contact');
			case 'user>item': 									return import('./user/user');
			case 'profile>notifications': 			return import('./profile/notifications');
			case 'profile>payments': 						return import('./profile/payments');
			// case 'detail-notifications': 				return import('./detail/notifications');
			case 'auction>mutate': 							return import('./auction/auction');
			default: return null;
			}
		});
	}

	// Form settings
	const loadFormSettings = () => {
		switch (name) {
		case 'auth>register': 								return require('../../requests/mutation/auth/register.js').default;
		case 'auth>login': 										return require('../../requests/mutation/auth/login.js').default;
		case 'auth>forgot-password-init': 		return require('../../requests/mutation/auth/forgot_password_init.js').default;
		case 'auth>forgot-password-reset': 		return require('../../requests/mutation/auth/forgot_password_reset.js').default;
		case 'contact>item': 									return require('../../requests/mutation/contact/mutate.js').default;
		case 'user>item': 										return require('../../requests/mutation/user/update.js').default;
		case 'profile>notifications': 				return require('../../requests/mutation/profile/notifications.js').default;
		case 'profile>payments': 							return require('../../requests/mutation/profile/payments.js').default;
		// case 'detail-notifications': 					return require('../../requests/mutation/detail/notifications.js').default;
		case 'auction>mutate': 								return require('../../requests/mutation/auction/mutate.js').default;
		default: return null;
		}
	};

	// Form query
	const loadFormQuery = () => {
		switch (name) {
		case 'auction>mutate': 								return require('../../requests/query/auction/item_prefill.js').default;
		case 'contact>item': 									return require('../../requests/query/contact/item.js').default;
		case 'profile>notifications': 				return require('../../requests/query/user/item.js').default;
		default: 															return require('../../requests/query/void.js').default;
		}
	};

	if (formSettings.current === null) {
		formSettings.current = loadFormSettings();
		formQuery.current = loadFormQuery();

		// Generate refs for all fields, which has validation (to manipulate after submit validation error - ex. scroll to field)
		if (formSettings.current && formSettings.current.validation) {
			Object.keys(formSettings.current.validation).map((fName) => {
				fieldsRefs.current[fName] = React.createRef();
				return true;
			});
		}
	}

	const getFormSettings = (section, key) => {
		if (formSettings.current) {
			const settings = formSettings.current[section] || formSettings.current;
			if (key) return settings[key] || null;
			return settings;
		}

		return null;
	};

	// Init mutation
	const [loading, setLoading] = useState(false);
	const { mutation: gqlMutation } = useGQL(getFormSettings('request', 'name'));

	// Form submit handle
	const [submitCnt, incSubmitCnt] = useState(0);

	const onSubmitForm = (state) => {

		if (disabled === false) {

			// Request to server only, if graphql scheme exist
			if (gqlMutation) {
				setLoading(true);
				console.log();
				if (onBeforeSubmitToServer) onBeforeSubmitToServer(state);

				incSubmitCnt(submitCnt + 1);
				gqlMutation(state).then((response) => {
					const d = response.request || {};
					const err = gqlValidation(response);

					if (err) {
						scrollToFirstError(setError(err, true), fieldsRefs.current);
						if (onError) onError(err, response);
					} else if (onSuccess && d) {
						// Append server response data to form local state .. specialy useful, when new record is created and user should update form again ..
						if (formParameters.onSuccessResponseToState && formParameters.onSuccessResponseToState === true) {
							updateState(d);
						}

						handleOnSuccess(d);
					}

					setLoading(false);
				});
			} else {
				// If server request is not set, then just return data to parent
				handleOnSuccess(state);
			}
		} else {
			// Find first error and scroll to field + focus
			scrollToFirstError(state.firstErrorFieldName, fieldsRefs.current);
		}
	};

	const handleOnSuccess = (data) => {
		// Handle callback
		const onSuccessType = typeof onSuccess;

		switch (onSuccessType) {
		default: // function
			onSuccess(data);
			break;

		case 'object': {
			if (onSuccess.action) {
				switch (onSuccess.action) {

				case 'FORM_NOTIFICATION':
					// console.log('Success!! Show form notification!');
					break;

				default:
					// console.log('DO NOT KNOW, WHAT TO DO ;)');
					break;
				}
			}

			break;
		}
		}
	};

	// Load form data (init)
	// const isQuery = (id && formQuery.current.properties.name !== 'void');
	const validationRules = getFormSettings('validation');
	const gqlSchema = getFormSettings('gql', 'schema');

	// Init form handler
	const { state, handleFocus, handleOnChange, handleOnSubmit, prepareAPIReadyData, validateForm, findFirstErrorFieldName, setError, updateState, updateChanged, disabled } = useForm(id ? 'update' : 'insert', initState, validationRules, onSubmitForm);

	// Load form data (run)
	const [variables, setVariables] = useState(formParameters.queryIdFieldName ? { [formParameters.queryIdFieldName]: id } : { id });
	const { query } = useGQL(formQuery.current.request.name, 'contact-profile');
	const { isFetching, isSuccess, data, refetch } = query(variables, { enabled: false });

	useEffect(() => {
		if (id) setVariables(formParameters.queryIdFieldName ? { [formParameters.queryIdFieldName]: id } : { id });
	}, [id]);

	useEffect(() => {
		if (id) refetch();
	}, [variables]);

	useEffect(() => {
		if (id && isFetching === false && isSuccess === true) {
			const d = (data && data.request) ? data.request : null;
			const e = d.error ? { error: true, errorCode: d.errorCode || null, errorMessage: d.message || 'n/a error message.' } : null;

			if (d) {
		 		updateState(d);
		 		if (onPrefillSuccess) onPrefillSuccess(d);
		 } else if (e) {
			 if (onPrefillError) onPrefillError(e);
		 }
		}
	}, [isFetching]);

	// if (isFetching === true) return <Loader />;
	return <Component.current input={{ state, placeholders, onChange: handleOnChange, onFocus: handleFocus, onBlur: handleFocus }} fieldsRefs={fieldsRefs.current || {}} handleOnSubmit={handleOnSubmit} validateForm={validateForm} updateState={updateState} findFirstErrorFieldName={findFirstErrorFieldName} prepareAPIReadyData={prepareAPIReadyData} disabled={disabled} loading={loading} isQueryLoading={isFetching} notification={notification} updateChanged={updateChanged} formParameters={formParameters} />;
});

FormLoader.defaultProps = {
	id: null,
	initialState: {},
	placeholders: {},
	formParameters: {},
	onSuccess: null,
	onError: null,
	onPrefillSuccess: null,
	onPrefillError: null
};

FormLoader.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	initialState: PropTypes.shape({}),
	placeholders: PropTypes.shape({}),
	formParameters: PropTypes.shape({}),
	onSuccess: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({})
	]),
	onError: PropTypes.func,
	onPrefillSuccess: PropTypes.func,
	onPrefillError: PropTypes.func
};

export default FormLoader;
